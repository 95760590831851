/**
 * SocialShare component that...
 *
 */
import * as React from 'react'
import useSiteMetadata from '../hooks/use-site-metadata'
import * as shareStyles from '../styles/social-share.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faLinkedin,
  /*   faMastodon, */
  faTwitter,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'

export default function SocialShare(props: SocialShareProps) {
  const { siteUrl } = useSiteMetadata()
  const postUrl = new URL(props.fields.slug, siteUrl)
  const hashtags =
    props.frontmatter.tags?.join(',').replace(/[#\s_-]/g, '') || ''
  const urlFacebook = encodeURI(
    `https://www.facebook.com/sharer.php?u=${postUrl}`
  )
  const urlLinkedin = encodeURI(
    `https://www.linkedin.com/shareArticle?url=${postUrl}&title=${props.frontmatter.title}`
  )
  /*   const urlMastodon = encodeURI(`https://mastodon.online/share?text=${postUrl} ${hashtags}`) */
  const urlTwitter = encodeURI(
    `https://twitter.com/share?url=${postUrl}&text=${props.frontmatter.title}&via=[via]&hashtags=${hashtags}`
  )
  const urlWhatsapp = encodeURI(
    `https://api.whatsapp.com/send?text=${props.frontmatter.title} ${postUrl}`
  )
  /* console.log('----- SocialShare: ', data) */
  return (
    <div className={shareStyles.shareContainer}>
      <h3>Share On</h3>
      <a
        href={urlLinkedin}
        className={`${shareStyles.linkedin} ${shareStyles.social}`}
        title="Share on LinkedIn"
      >
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </a>
      {/*
      <a href={urlMastodon}
        className={`${shareStyles.mastodon} ${shareStyles.social}`}
        title="Share on Mastodon">
        <FontAwesomeIcon icon={faMastodon} size="2x" />
      </a>
      */}
      <a
        href={urlTwitter}
        className={`${shareStyles.twitter} ${shareStyles.social}`}
        title="Share on Twitter"
      >
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </a>
      <a
        href={urlFacebook}
        className={`${shareStyles.facebook} ${shareStyles.social}`}
        title="Share on Facebook"
      >
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a
        href={urlWhatsapp}
        className={`${shareStyles.whatsapp} ${shareStyles.social}`}
        title="Share on WhatsApp"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </a>
    </div>
  )
}
interface SocialShareProps {
  frontmatter: {
    title: string
    tags: string[]
  }
  fields: {
    slug: string
  }
}
