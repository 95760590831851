/**
 * BlogPostPage template that...
 *
 */
import * as React from 'react'
// utilities
import { graphql } from 'gatsby'
import sanitizeHtml from 'sanitize-html'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Toc from '../components/toc'
import ContentMeta from '../components/content-meta'
import ContentTaxonomy from '../components/content-taxonomy'
import SocialShare from '../components/social-share'
import titleCase from '../../utils/titlecase'

function BlogPostPage({ data, pageContext }) {
  /* console.log('-----BlogPostPage: ', data, pageContext) */
  const { frontmatter, body, excerpt } = data.mdx
  const { slug } = data.mdx.fields
  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description}
        image={getSrc(frontmatter.bannerImage)}
        type="article"
        path={slug}
      />
      {
        // TODO: consider dropping hero image
        // in favour of smaller image
        <header className="l-header">
          <div className="l-banner-image">
            <GatsbyImage
              imgClassName="banner-image"
              image={getImage(frontmatter.bannerImage)}
              alt="FIX this — free license by Author from Pixabay.com"
            />
          </div>
          <div className="l-banner-overlay"></div>
          <div className="l-banner-content">
            <div className="l-constrained text-light text-shadow">
              <h1 id="page-title" className="page-title">
                {titleCase(frontmatter.title)}
              </h1>
              <p className="l-constrained-tighter page-lead">{excerpt}</p>
              <ContentMeta {...data.mdx} />
              <p className="image-caption">{frontmatter.header?.caption}</p>
            </div>
          </div>
        </header>
      }
      <main className="l-main">
        <div className="l-constrained">
          {frontmatter.toc ? (
            <div className="l-grid-sb1">
              <aside className="sidebar">
                <Toc {...data.mdx} />
              </aside>
              <article className="main">
                <MDXRenderer>{body}</MDXRenderer>
                <footer>
                  {
                    // FIX: enable taxonomy
                    /* <ContentTaxonomy {...frontmatter} /> */
                  }
                  <SocialShare {...data.mdx} />
                </footer>
              </article>
            </div>
          ) : (
            <>
              <article className="main">
                <MDXRenderer>{body}</MDXRenderer>
                <footer>
                  {
                    // FIX: enable taxonomy
                    /* <ContentTaxonomy {...frontmatter} /> */
                  }
                  <SocialShare {...data.mdx} />
                </footer>
              </article>
            </>
          )}
        </div>
      </main>
    </Layout>
  )
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query blogPostByIdQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      excerpt
      timeToRead
      body
      tableOfContents
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        dateTime: date
        description
        author
        header {
          caption
        }
        categories
        tags
        toc
        bannerImage {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 90
              formats: [AUTO, WEBP, AVIF]
              breakpoints: [400, 750, 1080, 1366, 1920, 2560]
              placeholder: BLURRED
            )
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
export default BlogPostPage
