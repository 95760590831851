/**
 * ContentMeta component that...
 *
 */
import * as React from 'react'
//import { Link } from 'gatsby'
//import kebabCase from 'lodash/kebabCase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faClock,
  faUserCircle
} from '@fortawesome/free-regular-svg-icons'

const ContentMeta = (props: ContentMetaProps) => {
  return (
    <p>
      <span>
        <FontAwesomeIcon icon={faUserCircle} size="1x" aria-hidden="true" />
        {` `}
        {props.frontmatter.author}
        {/*
        <Link to={`/authors/${kebabCase(props.frontmatter.author)}`}>
          {props.frontmatter.author}
        </Link>
        */}
      </span>
      <br />
      <span>
        <FontAwesomeIcon icon={faCalendarAlt} size="1x" aria-hidden="true" />
        {` `}
        <time date={props.frontmatter.timeDate}>{props.frontmatter.date}</time>
      </span>{' '}
      |{' '}
      <span>
        <FontAwesomeIcon icon={faClock} size="1x" aria-hidden="true" />
        {` `}
        {props.timeToRead} min read
      </span>
    </p>
  )
}
interface ContentMetaProps {
  frontmatter: {
    date: string
    timeDate: string
    author: string
  }
  timeToRead: number
}
export default ContentMeta
